<template>
  <v-container
    fluid
  >
    <base-material-card
      class="px-5 py-3 "
      color="indigo"
      icon="mdi-help"
      inline
    >
      <template #after-heading>
        <div class="text-h3 font-weight-light">
          {{ $t('submit_question') }}
        </div>
      </template>
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="false"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="12"
              sm="6"
            >
              <span class="text-subtitle-1 font-weight-bold">{{ $t('title') }}</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="6"
            >
              <v-text-field
                v-model="editedItem.title"
                :label="$t('title')"
                :rules="Utils.ValidateRules.NotEmpty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
              sm="6"
            >
              <span class="text-subtitle-1 font-weight-bold">{{ $t('description') }}</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="6"
            >
              <v-textarea
                v-model="editedItem.content"
                :label="$t('problem')"
                :rules="Utils.ValidateRules.NotEmpty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-spacer/>
            <v-btn
              class="mb-2"
              color="primary"
              dark
              @click="summit()"
            >
              {{ $t('submit') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>

  import { sendMsg } from '../../../common/Utlis/api'
  import Utils from '../../../common/Utlis/Utils'
  import i18n from '@/i18n'

  export default {
    data: function () {
      return ({
        loading: true,
        desserts: [],
        editedIndex: -1,
        valid: true,
        Utils: Utils,
        editedItem: {
          title: '',
          content: '',
        },
        defaultItem: {
          title: '',
          content: '',
        },
      })
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$i18n.t('addNewPartition') : this.$i18n.t('edit')
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
      },
      summit () {
        if (!this.$refs.form.validate()) {
          return
        }
        Utils.showConfirm(i18n.t('are_you_sure'), i18n.t('SureToContinue'), () => {
          sendMsg(this.editedItem.title, this.editedItem.content).then(() => {
            Utils.toast(i18n.t('submit_success'))
            this.initialize()
          })
        })
      },
      close () {
        this.dialog = false
        this.editedIndex = -1
      },
    },
  }
</script>
